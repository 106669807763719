import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import DatePicker from "../validators/datepicker";
import validations from "../fieldValidations";
import ApiUris from "../utils/ApiUris";
import common from "../utils/common";
import clickStream from "../utils/clickStream";
import val from "../validators/targetCOGSValidator";
import avv from "../validators/anticipatedVolumeValidator";
import pc9v from "../validators/pc9Validator";
import pnv from "../validators/protoneedsValidator";
import snv from "../validators/sampleneedsValidator";
import configData from "../../config";
import AgGridThumbnail from '../components/AgGridThumbnail.vue'

async function beforeMount(vm) {
  vm.components.datePicker = DatePicker.getDatePicker();
  
  const lookupTypes = configData.lookupTypes;
  await Promise.all([
    common.fetchLookupData(vm, [lookupTypes.gtmStages, lookupTypes.schedules, lookupTypes.subclasses])
  ]);
  
  vm.columnDefs = getColumnDefs(vm);
  common.postMountColumnDefSetup(vm);
}

function getColumnDefs(vm) {
  return [
    {
      headerName: "",
      field: "checkbox",
      pinned: "left",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 45,
      cellClassRules: {
        required: row => row.node.isSelected() && rmValidation(row.data).length > 0
      }
    },

    {
      headerName: "Request ID",
      field: "id",
      pinned: "left",
      filter: true,
      width: 140,
      cellClass: "noneditableCol",
      suppressPaste: true,
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      }
    },
    // {
    //   headerName: "Parent ID",
    //   field: "parentId",
    //   cellClass: "noneditableCol",
    //   filter: true,
    //   pinned: "left",
    // },
  // {
  //   headerName: "Child ID",
  //   field: "childId",
  //   cellClass: "noneditableCol",
  //   filter: true,
  //   pinned: "left",
  // },
    {
      headerName: "Thumbnail",
      field: 'productUrl',
      pinned: "left",
      width:130,
      filter: false,
      cellRendererFramework: AgGridThumbnail,
    },
    {
      headerName: "Status",
      field: "lastAction",
      cellClass: "noneditableCol",
      pinned: "left",
      width: 150,
      filter: "agSetColumnFilter",
      valueGetter: function(params) {
        if (params.data.lastAction == "APPROVE") {
          return "APPROVED";
        } else if (params.data.lastAction == "REJECT") {
          return "REJECTED";
        } else if (
          params.data.lastAction == "NEW" ||  // when RM creates request, lastAction is set to NEW
          params.data.lastAction == "PULL_BACK" ||
          params.data.lastAction == "MORE_INFO_RESPONSE"
        ) {
          return "SUBMITTED";
        } else if (params.data.lastAction == "MORE_INFO") {
          return "MORE_INFO";
        } else {
          return params.data.lastAction;
        }
      },
      cellClassRules: {
      moreinfo: row => row.data.lastAction === "MORE_INFO",
      }
    },
    {
      headerName: "More Info Comments",
      field: "moreInfoComments",
      hide: true,  // overriden later 
      editable: row => row.node.data.lastAction === "MORE_INFO",
      cellClassRules: {
        noneditableCol: row => row.data.lastAction !== "MORE_INFO"
      },
      pinned: "left",
      cellEditorParams: {
        maxLength: 1000,
      },
      cellEditor: "agLargeTextCellEditor"
    },
    {
      headerName: "Curr Stage",
      field: "currentState",
      cellClass: "noneditableCol",
      filter: true,
      width: 130,
      pinned: "left",
      hide: true,
    },
    {
      headerName: "Rejected Comments",
      field: "rejectedComments",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Season",
      field: "season",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: vm.seasonCodes
      },
      filterParams: { comparator: common.seasonComparator },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "PC5/PC9",
      field: "similarPC9",
      cellEditor: pc9v,
      cellStyle: { overflow: "visible !important" },
      editable: true,
      filter: true,
      
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      // see newRequest calling getSeasonGTMStage for how this dropdown is populated for new requests
      headerName: "GTM Stage",
      field: "gtmStage",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData[configData.lookupTypes.gtmStages])
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
      },
      suppressPaste: true,
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },

    {
      headerName: "Brand",
      field: "brand",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.getBrandDropdownList(vm)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.brand, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.brand, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Clusters",
      field: "requestingRegion",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: vm.lookupData.cluster
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },

    {
      headerName: "Requesting Merchant",
      field: "requestingMerchant",
      editable: true,
      filter: true,
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },

    {
      headerName: "Channel",
      field: "channel",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",

      cellEditorParams: {
        values: configData.datafields["channels"]
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Biggest Volume Country",
      field: "country",
      cellEditor: "agRichSelectCellEditor",
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      },
      editable: true,
      cellEditorParams: function(row) {
        const countries = configData.datafields['region-countries'][row.data.requestingRegion];
        if (countries) return { values: countries };
      }
    },
    {
      headerName: "Request Reason",
      field: "requestReason",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: configData.datafields["requestReasonCodes"]
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.gender)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.gender, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.gender, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Category",
      field: "category",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.category)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.category, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.category, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Class & Subclass",
      field: "classSubClass",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: function(row) {
        var subClassValues = common.extractValues(vm.lookupData.classSubClass);
        var filteredSubClassValues = common.filterSubclasses(subClassValues, row);
        return {
            values: filteredSubClassValues.values
        }
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.classSubClass, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.classSubClass, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Price Tier",
      field: "priceTier",
      editable: true,
      filter: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.priceTier)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.priceTier, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.priceTier, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Type Of Request",
      field: "requestType",
      editable: true,
      filter: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.typeOfRequest)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.typeOfRequest, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.typeOfRequest, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },

    {
      headerName: "Pull Forward(Y/N)",
      field: "pullForward",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: configData.datafields["pullForward"]
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Explanation of Request",
      field: "requestExplanation",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 1000,
    },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },

    {
      headerName: "Product Description",
      field: "description",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 1000,
    },
      cellStyle: { overflow: "hidden" },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },

    {
      headerName: "Fabric",
      field: "fabric",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.fabric)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.fabric, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.fabric, params.value);
      },
    },

    {
      headerName: "Finish",
      field: "finish",
      editable: true
    },

    {
      headerName: "Target COGS",
      field: "targetCogs",
      cellEditor: val,
      editable: true,
      cellStyle: { overflow: "visible !important" },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Anticipated Volume",
      field: "anticipatedVolume",
      editable: true,
      cellEditor: avv,
      cellStyle: function(params) {
        if(params.value >= 1500 && params.value <= 5000){
          return { overflow: "visible !important" , backgroundColor: "yellow"}
        } else {
          return { backgroundColor: ""}
        }
        return { overflow: "visible !important" }
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      },
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      }
    },
    {
      headerName: "Last Season Offered",
      field: "lastSeasonOffered",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.lastSeasonOffered)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.lastSeasonOffered, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.lastSeasonOffered, params.value);
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Proto Needs",
      field: "protoNeeds",
      cellEditor: pnv,
      editable: true,
      cellStyle: { overflow: "visible !important" },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      },
    },
    {
      headerName: "Sample Needs",
      field: "sampleNeeds",
      editable: true,
      cellEditor: snv,
      cellStyle: { overflow: "visible !important" },
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Product Line",
      field: "productLine",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.productLine)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.productLine, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.productLine, params.value);
      },
      cellStyle: { overflow: "visible !important" },
    },
    {
      headerName: "Account Name",
      field: "accountName",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 1000,
      },
      cellStyle: { overflow: "visible !important" },
    },
    {
      headerName: "Request On Floor (MM/DD/YYYY)",
      field: "requestOnFloor",
      editable: true,
      cellEditor: DatePicker.getDatePicker(vm.lookupData[configData.lookupTypes.schedules], configData),
      valueGetter: row => common.dateValueGetter(row.data.requestOnFloor),
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "In DC SIM (MM/DD/YYYY)",
      field: "inDCDate",
      editable: true,
      cellEditor: "datePicker",
      valueGetter: row => common.dateValueGetter(row.data.inDCDate),
      cellClassRules: {
        required: row => row.node.isSelected() && !RM_VALIDATION_MAP[row.colDef.field](row.data)
      }
    },
    {
      headerName: "Submission Date (MM/DD/YYYY)",
      field: "submissionDate",
      valueGetter: fieldValue => {
        return moment(fieldValue.data["submissionDate"]).format(
          "MM/DD/YYYY"
        );
      },
      cellClass: "noneditableCol"
    },
    {
      headerName: "Exclusivity",
      field: "exclusivity",
      editable: row => row.data.accountName,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: configData.datafields["exclusivity"]
      },
      cellClassRules: {
        noneditableCol: row => !row.data.accountName
      },
    },
    {
      headerName: "Image Reference PC9",
      field: "imageReferencePC9",
      editable: true,
    },
    //Added additional fields and hide for RIO-132
    {
      headerName: "FA Code",
      field: "faCode",
      hide: true,
      suppressToolPanel: true

    },
    {
      headerName: "GD Contact",
      field: "gdContact",
      hide: true,
      suppressToolPanel: true

    },
    {
      headerName: "Global Merchant",
      field: "globalMerchant",
      hide: true,
      suppressToolPanel: true

    },
    {
      headerName: "Fabric Description",
      field: "fabricDescription",
      hide: true,
      suppressToolPanel: true

    },
    {
      headerName: "Color Look",
      field: "colorLookCode",
      hide: true,
      suppressToolPanel: true

    },
    {
      headerName: "PD Contact",
      field: "pdContact",
      hide: true,
      suppressToolPanel: true

    },
  ];
}

function updated(vm) {
  if (!vm.columnDefs)
    return;

  let rejectedCols = vm.rowData.filter(function(item) {
    return item.lastAction == "REJECT";
  });
  if (rejectedCols && rejectedCols.length != 0) {
    vm.gridOptions.columnApi.setColumnVisible("rejectedComments", true);
  } else {
    vm.gridOptions.columnApi.setColumnVisible("rejectedComments", false);
  }

  let moreInfoCols = vm.rowData.filter(function(item) {
    return item.lastAction == "MORE_INFO";
  });
  if (moreInfoCols && moreInfoCols.length != 0) {
    vm.gridOptions.columnApi.setColumnVisible("moreInfoComments", true);
  } else {
    vm.gridOptions.columnApi.setColumnVisible("moreInfoComments", false);
  }
}

/**
 * On-click "+Add Requests" button
 * @param {*} vm 
 */
async function newRequest(vm) {
  vm.loading = true;
  const headers = common.getHeaders(vm.token);

  // available id of new request
  axios.get(ApiUris.idList + vm.idCount, headers).then(response => {
    vm.loading = false;
    const highest_id = response.data;
    const lowest_id = response.data - vm.idCount;
    const ids = [];

    for (var i = highest_id; i > lowest_id; i--) {
      ids.push(i);
    }
    const newData = [];
    const season = getSeason(vm.lookupData[configData.lookupTypes.schedules]);
    
    // create a row (new request) for each id and some defaults values
    ids.forEach(function(res) {
      const newRow = {
        id: res,
        submissionDate: moment().format("MM/DD/YYYY"),
        season: "",
        lastAction: "New",
        protoNeeds: '0',
      };
      if (configData.datafields.cluster.includes(vm.region))
        newRow.requestingRegion = vm.region;  // user's region
      newData.push(newRow);
      vm.new_requests.push(newRow["id"]);
    });

    // UPDATE ROW
    vm.gridApi.updateRowData({
      add: newData,
      addIndex: 0
    });

    vm.gridApi.forEachNode(node => {
      if (ids.includes(node.data["id"])) {
        node.setSelected(true);
        node.setDataValue('season', node.data["season"]);
        node.setDataValue('requestingRegion', node.data["requestingRegion"]);
      }
    });

    const sortid = [{ colId: "id", sort: "desc" }];
    vm.gridApi.setSortModel(sortid);
    vm.gridApi.flashCells({
      rowNodes: [0, 10]
    });

    // get filter from cache
    const existingFilter = JSON.parse(localStorage.getItem(vm.filterCacheName));
    const res = {};
    for (let key in existingFilter) {
      res[key] = existingFilter[key];
    }

    // hlin ???
    const keySet = new Set([
      "gender",
      "category",
      "brand",
      "requestingMerchant",
      "channel",
      "priceTier",
      "typeOfRequest",
      "lastSeasonOffered"
    ]);
    for (let key in res) {
      if (keySet.has(key)) {
        res[key]["values"].push(null);
      }
    }
    clickStream.obEvent('Request', 'Add New Requests')

    common.setFilters({}, vm);
    common.setFilters(res, vm);
  }).catch(error => {
    console.error(error);
    if (error.message && error.message.toLowerCase().startsWith('network error')) {
      common.pushRoute(vm.$router, '/auth-callback');
    }
  });
}


function getSeason(seasons_gtm_list) {
  seasons_gtm_list = seasons_gtm_list.sort(function(a, b) {
    var c = new Date(a.start);
    var d = new Date(b.start);
    return c - d;
  });

  // get season/gtm pair based on today's date
  var result_stage_season = seasons_gtm_list[0]["season_gtm"];
  today = moment();
  var season, season_gtm, start, end, today;
  for (let i = 0; i <= seasons_gtm_list.length - 1; i++) {
    season_gtm = seasons_gtm_list[i];
    start = moment(season_gtm["start"], "MM/DD/YYYY");
    end = moment(season_gtm["end"], "MM/DD/YYYY");

    if (today.isBetween(start, end) || today.isSame(start) || today.isSame(end)) {
      // found the pair
      season = seasons_gtm_list[i]["season_gtm"][0];
      break;
    } else if (today > end) {
      // did not find it so we'll use this one
      result_stage_season = seasons_gtm_list[i]["season_gtm"];
      // hlin: no break?
    }
  }
  
  // hlin: im assuming here is when it never hits the first conditional above?
  // use off calendar if todays date does not fall between start+end of any 
  // of the schedule seasons
  if (season === undefined) {
    season = result_stage_season[0];
  }
  return season;
}


async function save(vm) {
  vm.loading = true;
  vm.gridApi.stopEditing();
  vm.gridApi.refreshCells();
  const rows = vm.gridApi.getSelectedNodes();
  if (!rows.length) {
    vm.loading = false;
    swal.fire({
      title: "Please Select a Request",
      type: "error",
      confirmButtonText: "Ok"
    });
    return;
  }

  for (const row of rows) {
    const invalidFields = rmValidation(row.data);
    if (invalidFields.length > 0) {
      vm.loading = false;
      swal.fire({ 
        type: "error",
        text: "Please correctly fill in the missing or invalid fields in the selected rows",
        confirmButtonText: "Ok"
      });
      return;
    }
  }

  // divide selected product requests to to-update and to-create
  let requestsToUpdate = [];
  const requestsToCreate = [];
  for (let row of rows) {
    const data = vm.gridApi.getRowNode(row.id).data;
    if (vm.new_requests.includes(data["id"]) || data.lastAction === 'Saved') {  // autosaved requests
      requestsToCreate.push(data);
    } else {
      requestsToUpdate.push(data);
    }
  }

  // filtering and building create product request promise
  for (let row of requestsToCreate) {
    for (let key of Object.keys(row)) {
      const value = row[key];
      if (value === "" || value === null || value === undefined) {
        delete row[key];
      }
    }
  }

  // filtering and dividing out more-info from update product requests
  for (let row of requestsToUpdate) {
    for (let key of Object.keys(row)) {
      const value = row[key];
      if (value === "" || value === null || value === undefined) {
        delete row[key];
      }
    }
  }
  
  // building update and moreinfo product request promise
  let requestsNeedingMoreInfo = [];
  if (requestsToUpdate.some(row => row.lastAction === "MORE_INFO")) {
    const resp = await common.hasMoreInfoReq(vm);
    if (resp && resp.value) {
      requestsNeedingMoreInfo = requestsToUpdate.filter((row) => row.lastAction === "MORE_INFO");
      requestsToUpdate = requestsToUpdate.filter(row => row.lastAction !== "MORE_INFO");
    }
  }

  try {
    const values = await Promise.all([
      common.createUpdateReturnRequest(requestsToCreate, common.CREATE_REQUEST, vm),
      common.createUpdateReturnRequest(requestsToUpdate, common.UPDATE_REQUEST, vm),
      common.createUpdateReturnRequest(requestsNeedingMoreInfo, common.RETURN_REQUEST, vm),
    ]);
    if (values && values.length === 3 && !values.includes(undefined) && !values.includes(NaN)) {
      const numCreated = values[0] || 0;
      const numUpdated = parseInt(values[1] || 0);
      const numReturned = parseInt(values[2] || 0);
      let mes = [];
      if (requestsToCreate.length > 0) {
        mes.push("Requests created : " + numCreated);
      }
      if (requestsToUpdate.length > 0 || requestsNeedingMoreInfo.length > 0) {
        mes.push("Requests updated : " + (numUpdated + numReturned));
      }

      let alertType;
      if (numCreated < requestsToCreate.length || numUpdated < requestsToUpdate.length || numReturned < requestsNeedingMoreInfo.length) {
        alertType = "warning";
        mes.push("(some requests may not have been created or updated)");
      } else {
        alertType = "success";
      }

      clickStream.obEvent('Submit', 'Submitted by Regional Merch')
      swal.fire({
        text: mes.join(", "),
        type: alertType,
        confirmButtonText: "Ok"
      });
    } else {
      // eslint-disable-next-line
      vm.loading = false;
      swal.fire({
        text: "Failed! Error in creating/updating the request",
        type: "error",
        confirmButtonText: "Ok"
      });
    }
  } catch (e) {
    // eslint-disable-next-line
    vm.loading = false;
    console.error(e);
    swal.fire({
      text: "Failed! Error in creating/updating the request",
      type: "error",
      confirmButtonText: "Ok"
    });
  }
  
  // eslint-disable-next-line
  vm.loading = false;
  await common.fetchGridContents(vm);
  vm.gridApi.deselectAll();
}

function drop(vm) {
  common.dropRequest(vm)
}

/**
 * 
 * @param {*} params 
 * @returns {Array} array of failing fields
 */
function rmValidation(data) {
  const fieldsFailing = Object.keys(RM_VALIDATION_MAP).filter(k => !RM_VALIDATION_MAP[k](data));
  console.debug(`Fields failing validation for product request ${data.id}:`, fieldsFailing);
  return fieldsFailing;
}

const gv = validations.generalTextValidation;
const RM_VALIDATION_MAP = {
  // base required
  season: data => gv(data.season),
  gtmStage: data => gv(data.gtmStage),
  requestingMerchant: data => gv(data.requestingMerchant),
  pullForward: data => gv(data.pullForward),
  requestOnFloor: data => gv(data.requestOnFloor),
  requestType: data => gv(data.requestType),
  category: data => gv(data.category),
  classSubClass: data => gv(data.classSubClass),
  // submissionDate: data => validations.generalTextValidation(data.submissionDate), // should be autofilled by the valuegetter
  brand: data => gv(data.brand),
  gender: data => gv(data.gender),
  requestingRegion: data => gv(data.requestingRegion),
  lastSeasonOffered: data => gv(data.lastSeasonOffered),

  // New request type
  anticipatedVolume: data => data.requestType !== 'New' || validations.anticipatedVolume(data.anticipatedVolume),
  inDCDate: data => data.requestType !== 'New' || validations.dateValidation(data.inDCDate),
  requestReason: data => data.requestType !== 'New' || gv(data.requestReason),
  requestExplanation: data => data.requestType !== 'New' || gv(data.requestExplanation),
  country: data => data.requestType !== 'New' || validations.countryValidation(data.requestingRegion, data.country),
  channel: data => data.requestType !== 'New' || gv(data.channel),
  priceTier: data => data.requestType !== 'New' || gv(data.priceTier),
  targetCogs: data => data.requestType !== 'New' || gv(data.targetCogs),
  protoNeeds: data => data.requestType !== 'New' || validations.protoNeeds(data.protoNeeds),
  sampleNeeds: data => data.requestType !== 'New' || validations.sampleNeeds(data.sampleNeeds),

  // non-New 
  similarPC9: data => data.requestType === 'New' || validations.pc9Validation(data.similarPC9),
  description: data => data.requestType === 'New' || gv(data.description),
};

export default {
  beforeMount,
  updated,
  save,
  drop,
  newRequest,
  rmValidation,
  getColumnDefs,
}