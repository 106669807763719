<template>
  <div style="padding:15px" v-bind:id="viewId()">
    <div id="loading" v-if="loading"></div>
    <div class="row table-buttons">
      <!-- HEADER BUTTONS -->
      <!-- Regional Merch only, for creating new requests -->
      <label v-if="role === 'Regional Merch'" for="reqNumber" class="col-6">Number of requests</label>
      <input v-if="role === 'Regional Merch'" 
        type="number"
        id="reqNumber"
        class="col-6"
        min="1"
        v-model="idCount"
        style="margin-left:10px;width:51px"
        data-bind="value:reqNumber"
      />
      <button v-if="role === 'Regional Merch'" 
        v-on:click="newrequest()"
        class="glyphicon glyphicon-plus button-secondary pure-button req-legend"
      >Add Requests</button>
      <button
        v-on:click="clearFilters()"
        class="button-secondary pure-button req-legend"
      >Clear Filters</button>
      
      <button v-if="role !== 'Summary'"
        variant="secondary"
        v-on:click="save()"
        :class="role === 'Regional Merch' ? 'button-secondary pure-button req-legend float-right' : 'button-secondary pure-button req-legend float-left'"
      >Submit</button>

      <button v-if="role == 'Summary' && actualRole != null && shouldShowDropAction()"
        variant="secondary"
        v-on:click="drop()"
        :class="'button-secondary pure-button req-legend float-left'"
      >Drop Requests</button>
      
      <label v-if="actualRole === 'SuperUser' && role !== 'Summary'" for="reqNumber" class="col-6">Change Role</label>
      <select v-if="actualRole === 'SuperUser' && role !== 'Summary'" v-model="role" @change="onRoleChange($event)" style="margin-left:10px">
          <option v-for="role in roleOrder" :value="role" :key="role">
            {{ role }}
          </option>
      </select>
      <input type="file" ref="fileInput" style="display: none" @change="uploadExcel">
       <button v-if="role === 'Regional Merch' || role === 'Global Merch'"
        v-on:click="openFilleUpload()"
        variant="secondary"
        class="button-secondary pure-button req-legend float-right"
      >Upload CSV</button>
      <button
        v-on:click="download()"
        variant="secondary"
        class="button-secondary pure-button req-legend float-right"
      >Download As Excel</button>
    </div>

    <!-- AG-GRID TABLE -->
    <div class="ag-theme-balham">
      <ag-grid-vue
        style="padding:10px;margin-top:10px;margin-left:17px;height:420px"
        :columnDefs="columnDefs"
        :rowData="rowData"
        rowSelection="multiple"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :gridOptions="gridOptions"
        :components="components"
        rowMultiSelectWithClick="true"
        suppressRowClickSelection="true"
        :enableCellChangeFlash="true"
        :defaultColDef="defaultColDef"
        floatingFilter="true"
        @filterChanged="filterChanged"
        :rowClassRules="rowClassRules"
        @rowSelected="rowSelected"
        @grid-ready="onGridReady"
        @cellValueChanged="onCellValueChanged"
      ></ag-grid-vue>
    </div>
    
    <!-- FOOTER -->
    <div class="row table-footer">
      <!-- pagination -->
      <div class="col-md-3">
        <label for="reqNumber" class="col-6">Display Rows</label>
        <input
          type="number"
          id="reqNum"
          value="5"
          class="col-6"
          @change="changePagination()"
          min="5"
          v-model="pageSize"
          style="margin-left:10px;width:51px; margin-bottom: 25px;"
          data-bind="value:reqNumber"
        />
      </div>

      <!-- Show recent requests for non-regional merch roles -->
      <div v-if="role !== 'Regional Merch' && role !== 'Summary'" class="col-md-offset-4" style="margin-top:8px">
        <div class="col-md-3">
          <label class="legend" style="background-color:#CDB77B;color:black">Most Recent Requests</label>
        </div>
      </div>

      <!-- summary screen only -->
      <div v-if="role === 'Summary'" class="container">
        <div class="row" style="padding:6px">
          <div class="col-md-2 col-md-offset-1">
            <label class="legend" style="background-color:#32CD32;color:black">Approved Requests</label>
          </div>
          <div class="col-md-2">
            <label class="legend" style="background-color:#808080;color:black">Dropped Requests</label>
          </div>
          <div class="col-md-2">
            <label class="legend" style="background-color:#FF0000;color:white">Rejected Requests</label>
          </div>
        </div>
      </div>
    </div>

    <!-- DEVELOPER SECTION -->
    <div v-if="actualRole === 'SuperUser' && role !== 'Summary'" class="">
      <label v-if="actualRole === 'SuperUser' && role !== 'Summary'" class="col-6">State Legend</label>
      <div>0: New</div>
      <div>1: Regional Merchant Approved </div>
      <div>2: Regional Planning Lead Approved </div>
      <div>3: Pending Global Merchant Approved </div>
      <div>4: Global Merchant Approved </div>
      <div>5: PD Approved </div>
      <div>6: SP Approved </div>
    </div>
    <div v-if="role === 'Regional Merch' || role === 'Global Merch'" style="margin-top: 15px;">
      <label class="">Template Loader File</label>
      <div v-if="role === 'Regional Merch'"><a @click="getTemplate" style="cursor:pointer;">RM Uploader Template</a></div>
      <div v-if="role === 'Global Merch'"><a @click="getTemplate" style="cursor:pointer;">GM Uploader Template</a></div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import moment from "moment";
import configData from "../../config";
import "../assets/styles.css";
import regionalMerch from "../roleFunctions/regionalMerch";
import regionalLead from "../roleFunctions/regionalLead";
import regionalPlanningLead from "../roleFunctions/regionalPlanningLead";
import globalMerch from "../roleFunctions/globalMerch";
import globalMerchLead from "../roleFunctions/globalMerchLead";
import productDev from "../roleFunctions/productDev";
import summary from "../roleFunctions/summary";
import supplyPlanning from "../roleFunctions/supplyPlanning";
import common from "../utils/common";
import clickStream from "../utils/clickStream";
import swal from "sweetalert2";

moment.suppressDeprecationWarnings = true;

export default {
  data() {
    const roleFunctionMap = {};
    roleFunctionMap[configData.roles.RM] = regionalMerch;
    roleFunctionMap[configData.roles.RL] = regionalLead;
    roleFunctionMap[configData.roles.RPL] = regionalPlanningLead;
    roleFunctionMap[configData.roles.GM] = globalMerch;
    roleFunctionMap[configData.roles.GML] = globalMerchLead;
    roleFunctionMap[configData.roles.PD] = productDev;
    roleFunctionMap[configData.roles.SP] = supplyPlanning;
    roleFunctionMap[configData.roles.SUM] = summary;
    return {
      username: localStorage.getItem('username'),
      region: localStorage.getItem("region"),  // region for user
      token: localStorage.getItem("idToken"),
      role: this.$router.currentRoute.meta.roleType,  // based on route
      actualRole: localStorage.getItem('role'),
      userBrand: localStorage.getItem('userBrand'),
      roleOrder: configData.roleOrder.map(rl => configData.roles[rl]),
      roleFunctionMap: roleFunctionMap,
      filterCacheName: this.$router.currentRoute.meta.roleType + " filter",
      loading: false,
      columnDefs: null,
      rowData: [],
      gridApi: null,
      gridOptions: { enableRangeSelection: true },
      defaultColDef: null,
      paginationPageSize: null,
      isValid: [],
      pageSize: 10,
      rowClassRules: null,
      components: {},
      new_requests: [],
      idCount: 1,
      seasons: [],
      seasonCodes: [],
      env: process.env.VUE_APP_API_ENV,
    }
  },
  
  components: {
    AgGridVue: AgGridVue
  },
  
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
   async onCellValueChanged(event) {
      const field = event.colDef.field;
      const oldValue = event.oldValue || '';
      const newValue = event.newValue || '';
      let lookupData;
      console.debug(`(${event.data.id}) ${field}: '${oldValue}' to '${newValue}'; mappedValue=<${event.data[field]}>`);
      // RIO-132 PLM Integration Look up
      if (field === 'similarPC9' || field === 'actualPC9') {
       lookupData = await common.autoPopulateValues(newValue ? newValue : oldValue, event.data['season'],  this);
       if(lookupData) {
       event.node.setDataValue('brand', lookupData.brand ? common.findValue('brand', lookupData.brand) : (event.data['brand'] !== undefined ? event.data['brand'] : null));
       event.node.setDataValue('gender', lookupData.gender ? common.findValue('gender', lookupData.gender) : (event.data['gender'] !== undefined ? event.data['gender'] : null));
       event.node.setDataValue('description', lookupData.description ? lookupData.description : (event.data['description'] !== undefined ? event.data['description'] : null));
       event.node.setDataValue('classSubClass', lookupData.classSubClass ? common.findValue('classSubClass',lookupData.classSubClass) : (event.data['classSubClass'] !== undefined ? event.data['classSubClass'] : null));
       event.node.setDataValue('category', lookupData.category ? common.findValue('category',lookupData.category) : (event.data['category'] !== undefined ? event.data['category'] : null));
       event.node.setDataValue('priceTier', lookupData.priceTier ? common.findValue('priceTier',lookupData.priceTier) : (event.data['priceTier'] !== undefined ? event.data['priceTier'] : null));
       event.node.setDataValue('requestType', lookupData.requestType ? common.findValue('typeOfRequest',lookupData.requestType) : (event.data['requestType'] !== undefined ? event.data['requestType'] : null));
       event.node.setDataValue('productLine', lookupData.productLine ? common.findValue('productLine',lookupData.productLine) : (event.data['productLine'] !== undefined ? event.data['productLine'] : null));
       event.node.setDataValue('fabric', lookupData.fabric ? common.findValue('fabric',lookupData.fabric) : (event.data['fabric'] !== undefined ? event.data['fabric'] : null));
       event.node.setDataValue('finish', lookupData.finish ? lookupData.finish : (event.data['finish'] !== undefined  ? event.data['finish'] : null));
       event.node.setDataValue('gtmStage', lookupData.gtmStage ? common.findValue('GTMStage', lookupData.gtmStage) : (event.data['gtmStage'] !== undefined ? event.data['gtmStage'] : null));
       event.node.setDataValue('lastSeasonOffered', lookupData.lastSeasonOffered ? common.findValue('lastSeasonOffered',lookupData.lastSeasonOffered) : (event.data['lastSeasonOffered'] !== undefined ? event.data['lastSeasonOffered'] : null));
       if(this.role === 'Regional Merch'){
       event.node.setDataValue('faCode', lookupData.faCode ? lookupData.faCode : null);
       event.node.setDataValue('gdContact', lookupData.gdContact ? lookupData.gdContact : null);
       event.node.setDataValue('globalMerchant', lookupData.globalMerchant ? lookupData.globalMerchant : null);
       event.node.setDataValue('fabricDescription', lookupData.fabricDescription ? lookupData.fabricDescription : null);
       event.node.setDataValue('colorLookCode', lookupData.colorLookCode ? lookupData.colorLookCode : null);
       event.node.setDataValue('pdContact', lookupData.pdContact ? lookupData.pdContact : null);
       }
       if(this.role === 'Global Merch'){
       event.node.setDataValue('faCode', lookupData.faCode ? lookupData.faCode : (event.data['faCode'] !== undefined && event.data['faCode'] !== '' && event.data['faCode'] !== null ? event.data['faCode'] : null));
       event.node.setDataValue('gdContact', lookupData.gdContact ? lookupData.gdContact : event.data['gdContact'] !== undefined && event.data['gdContact'] !== '' && event.data['gdContact'] !== null ? event.data['gdContact'] : null);
       event.node.setDataValue('globalMerchant', lookupData.globalMerchant ? lookupData.globalMerchant : event.data['globalMerchant'] !== undefined && event.data['globalMerchant'] !== '' && event.data['globalMerchant'] !== null ? event.data['globalMerchant'] : null);
       event.node.setDataValue('fabricDescription', lookupData.fabricDescription ? lookupData.fabricDescription : event.data['fabricDescription'] !== undefined && event.data['fabricDescription'] !== ''  && event.data['fabricDescription'] !== null ? event.data['fabricDescription'] : null);
       event.node.setDataValue('colorLookCode', lookupData.colorLookCode ? lookupData.colorLookCode : event.data['colorLookCode'] !== undefined && event.data['colorLookCode'] !== '' && event.data['colorLookCode'] !== null ? event.data['colorLookCode'] : null);
       event.node.setDataValue('pdContact', lookupData.pdContact ? lookupData.pdContact : event.data['pdContact'] !== undefined && event.data['pdContact'] !== '' && event.data['pdContact'] !== null ? event.data['pdContact'] : null);
       event.node.setDataValue('productLine', lookupData.productLine ? common.findValue('productLine',lookupData.productLine) : event.data['productLine'] !== undefined && event.data['productLine'] !== '' && event.data['productLine'] !== null ? event.data['productLine'] : null);
       event.node.setDataValue('fabric', lookupData.fabric ? common.findValue('fabric',lookupData.fabric) : event.data['fabric'] !== undefined && event.data['fabric'] !== '' && event.data['fabric'] !== null ? event.data['fabric'] : null);
       event.node.setDataValue('finish', lookupData.finish ? lookupData.finish : event.data['finish'] !== undefined && event.data['finish'] !== '' && event.data['finish'] !== null ?  event.data['finish'] : null);
       }
       }
      }
      this.validateDropDownValue(event);
      // auto saving
      let payload = {}
      let column = event.column.colId
      const AUTOSAVE_BLACKLIST = ['approval_state', 'toState'];
      if (!AUTOSAVE_BLACKLIST.includes(column) && event.data[field]) {
        // RIO-3 Handling In DC date
        if(this.role === 'Regional Merch' && column === 'requestOnFloor' /*&& !event.data.inDCDate*/) {
          let floorDate = new Date(event.value);
          let defaultDate =  moment(floorDate.setMonth(floorDate.getMonth()-1)).format("MM/DD/YYYY");
          payload['inDCDate'] = defaultDate
          event.node.setDataValue('inDCDate', defaultDate);
        }
        //Default value for protoneeds setting it as 0
        if(event.data.lastAction === 'New' && !event.data.protoNeeds) {
            payload['protoNeeds']= 0
        }
        payload['id']= event.data.id.toString()
        payload[column] = event.value
        common.autosave(this,payload)
      }

      // when a column value is changed and row was previously unselected, select it
      let selectRow = !event.node.isSelected() && oldValue !== newValue;
      
      // do below to avoid error loop of checkbox not getting unselected
      // error loop = unselect -> clear approval state -> trigger onchange -> reselect row bc it was unselected+value change
      const rolesWithAutofill = [
        configData.roles.RL,
        configData.roles.RM,
        configData.roles.RPL,
        configData.roles.GML,
        configData.roles.PD,
        configData.roles.SP,
      ];
      if (rolesWithAutofill.includes(this.role)) {
        // don't reselect row when approval_state or Assign To Role column gets cleared (see rowSelected function)
        selectRow = selectRow && !(field === 'approval_state' || field === 'toState');
      }

      if (selectRow)
        event.node.setSelected(true);
    },
    // RIO-198 copy-paste invalid data in grid 
    validateDropDownValue(params) {
      const { node, data, colDef } = params;
      if (colDef.cellEditorParams && colDef.cellEditorParams.values) {
      const isValid = colDef.cellEditorParams.values.includes(data[colDef.field]);
  
      if (!isValid && data[colDef.field] !== '') {
        if(colDef.field === 'requestingRegion'){
          node.setDataValue('country', "");
          node.data.modified = true;
        }
        node.setDataValue(colDef.field, "");
        node.data.modified = true;
      }
      if (node.data.modified && isValid) {
        node.data.modified = false;
      }
    } 
      if(colDef.field === 'classSubClass') {
        const filteredValues = colDef.cellEditorParams(params);
        const isSubClassValid = filteredValues.values.includes(data['classSubClass']);
        if(!isSubClassValid && data['classSubClass'] !== ''){
          node.setDataValue('classSubClass', "");
          node.data.modified = true;
        }
        if(node.data.modified && isSubClassValid) {
        node.data.modified = false;
        }
      }
    },

    viewId() {
      return (this.role === configData.roles.SP) ? "sp-approval" : "";
    },
    
    download() {
      clickStream.obEvent('Download', 'Download As Excel')
      this.gridApi.exportDataAsExcel();
    },
  
    changePagination() {
      const value = this.pageSize;
      this.gridApi.paginationSetPageSize(Number(value));
      this.paginationPageSize = value;
    },

    clearFilters() {
      common.setFilters({}, this);
    },
    
    filterChanged(params) {
      localStorage.setItem(this.filterCacheName, JSON.stringify(params.api.getFilterModel()));
    },

    /** Regional Merch only */
    newrequest() {
      this.roleFunctionMap[this.role].newRequest(this);
    },

    save() {
      this.roleFunctionMap[this.role].save(this);
    },

    shouldShowDropAction() {
      return common.shouldShowDropAction();
    },

    drop() {
      if (this.actualRole === "SuperUser") {
        common.dropRequest(this);
      } else {
        this.roleFunctionMap[this.actualRole].drop(this);
      }
    },

    rowSelected(row) {
      const vm = this;

      if (vm.role === configData.roles.SUM)
        return;

      vm.gridApi.refreshCells();
      if (vm.role === configData.roles.RM || vm.role === configData.roles.GM)
        return;
      
      // clear approval column if row gets unselected
      if (!row.node.isSelected()) {
        console.log('row is unselected, setting approval state to undefined')
        row.node.setDataValue("approval_state", undefined);
        return;
      }
      
      const data = row.node.data;
      const colApi = row.columnApi;
      const currentState = parseInt(data.currentState);
      switch (vm.role) {
        case configData.roles.RL:
          if (!data.approval_state && currentState !== 1) {
            row.node.setDataValue("approval_state", "Approve");
          }
          break;

        case configData.roles.RPL:
          if (!data.approval_state && currentState !== 2) {
            row.node.setDataValue("approval_state", "Approve");
          } else if (data.approval_state === "MORE_INFO") {
            colApi.setColumnVisible("toState", true);
            colApi.setColumnVisible("moreInfoComments", true);
          }
          break;

        case configData.roles.GML:
          if (!data.approval_state && currentState !== 4 && currentState !== 3) {
            row.node.setDataValue("approval_state", "Approve");
          }
          break;

        case configData.roles.PD:
          if (!data.approval_state && currentState !== 5) {
            row.node.setDataValue("approval_state", "Approve");
          } else if (data.approval_state === "MORE_INFO") {
            colApi.setColumnVisible("toState", true);
            colApi.setColumnVisible("moreInfoComments", true);
          }
          break;
      
        case configData.roles.SP:
          if (!data.approval_state) {
            row.node.setDataValue("approval_state", "Approve");
          } else if (data.approval_state === "MORE_INFO") {
            colApi.setColumnVisible("toState", true);
            colApi.setColumnVisible("moreInfoComments", true);
          }
          break;
      }
    },

    // as super user
    onRoleChange(event) {
      common.pushRoute(this.$router, common.getPathByRole(event.target.value));
    },
   async openFilleUpload() {
      this.$refs.fileInput.click()
    },
    async uploadExcel(e) {
      let file
      if(e.target.files){
        file = e.target.files[0]
        e.target.value = ''
      } else {
        file = e.dataTransfer.files[0]
        e.target.value = ''
      }
      if(file && file.type === 'text/csv') {
        var data = new FormData()
        data.append('file', file)
        let res = await common.uploadExcel(data, this)
        if(res) {
           {
            swal.fire({
              title: "File Uploaded Successfully!",
              type: "success",
              confirmButtonText: "Ok"
            });
      }
        }
      } else {
        swal.fire({
          title: "Please upload CSV file",
          type: "info",
          confirmButtonText: "Ok"
        });
      }
    },
   getTemplate() {
    let url = common.getRoleUrl(this)
    const a = document.createElement('a')
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    }
  },

  // called once
  async created() {
    await common.fetchGridContents(this);
  },

  // called once
  beforeMount() {
    // ag-grid defaults
    this.paginationPageSize = 10;

    this.defaultColDef = {
      sortable: true,
      unSortIcon: true,
    }
    if (this.role !== configData.roles.SUM) {
      this.defaultColDef.resizable = true;
      this.defaultColDef.singleClickEdit = true;
    }

    // see above for this function
    // this.defaultColDef.onCellValueChanged
    
    this.components = {};

    this.roleFunctionMap[this.role].beforeMount(this);
  },

  updated() {
    // debug
    this.gridOptions.columnApi.setColumnVisible("currentState", this.role === configData.roles.SUM || this.env === 'local');

    this.roleFunctionMap[this.role].updated(this);
  },
}
</script>