import DatePicker from "../validators/datepicker";
import validations from "../fieldValidations";
import common from "../utils/common";
import fcv from "../validators/fabricCodeValidator";
import configData from "../../config";
import AgGridThumbnail from '../components/AgGridThumbnail.vue'

async function beforeMount(vm) {
  vm.components.datePicker = DatePicker.getDatePicker();
  vm.rowClassRules = {
    recentRow: function(params) {
      var reqDate = new Date(params.data.modifiedDate);
      var today = new Date();
      today.setDate(today.getDate() - 5);
      if (reqDate > today) {
        return true;
      }
    }
  };
  
  const lookupTypes = configData.lookupTypes;
  await Promise.all([
    common.fetchLookupData(vm, [lookupTypes.gtmStages, lookupTypes.schedules])
  ]);
  
  vm.columnDefs = getColumnDefs(vm);
  common.postMountColumnDefSetup(vm);
}

function getColumnDefs(vm) {
  return [
    {
      headerName: "",
      field: "checkbox",
      pinned: "left",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,  // render checkbox
      width: 45,
      suppressPaste: true,
      cellClassRules: {
        required: row => row.node.isSelected() && pdValidation(row.data).length > 0,
        recentRow: function(params) {
          var reqDate = new Date(params.data.modifiedDate);
          var today = new Date();
          today.setDate(today.getDate() - 5);
          if (reqDate > today) {
            return true;
          }
        }
      }
    },
    {
      headerName: "Approve/Reject",
      field: "approval_state",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      suppressPaste: true,
      pinned: "left",
      width: 145,
      cellEditorParams: function(params) {
        if (params.data.currentState == 5) {
          return { values: ["PULL_BACK"] };
        } else {
          return { values: ["Approve", "Reject", "Drop", "MORE_INFO"] };
        }
      }
    },
    {
      headerName: "ID",
      field: "id",
      filter: true,
      pinned: "left",
      width: 120,
      cellClass: "noneditableCol",
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      }
    },
    // {
    //   headerName: "Parent ID",
    //   field: "parentId",
    //   width: 115,
    //   cellClass: "noneditableCol",
    //   filter: true,
    //   pinned: "left",
    // },
    {
      headerName: "Status",
      field: "lastAction",
      width: 150,
      pinned: "left",
      cellClass: "noneditableCol",
      filter: "agSetColumnFilter",
      valueGetter: function(params) {
        if (params.data.currentState == 5) {
          return "Approved";
        } else if (params.data.currentState == 4) {
          if (
            params.data.lastAction == "APPROVE" ||
            params.data.lastAction == "NEW" ||
            params.data.lastAction == "PULL_BACK" ||
            params.data.lastAction == "MORE_INFO_RESPONSE"
          ) {
            return "NEW";
          }
          if (params.data.lastAction == "MORE_INFO") {
            return "MORE_INFO";
          }
        }
      },
      cellClassRules: {
        moreinfo: function(params) {
          if (params.node.data.lastAction == "MORE_INFO") {
            return true;
          }
        }
      }
    },
    {
      headerName: "Thumbnail",
      field: 'productUrl',
      pinned: "left",
      width:130,
      filter: false,
      cellRendererFramework: AgGridThumbnail,
    },
    {
      headerName: "Curr Stage",
      field: "currentState",
      cellClass: "noneditableCol",
      filter: true,
      width: 130,
      pinned: "left",
      hide: true,
    },
    {
      headerName: "Assign To Role",
      field: "toState",
      editable: row => row.data.approval_state === "MORE_INFO",
      width: 140,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: function() {
        return common.assignToRoleDropdownValues();
      },
      cellClassRules: {
      noneditableCol: row => !(row.data.approval_state === "MORE_INFO"),
      required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "More Info Comments",
      field: "moreInfoComments",
      editable: row => (row.data.lastAction === "MORE_INFO" || row.data.approval_state === "MORE_INFO"),
      cellClassRules: {
      noneditableCol: row => !(row.data.lastAction === "MORE_INFO" || row.data.approval_state === "MORE_INFO")
      },
      cellEditorParams: {
        maxLength: 1000,
      },
      cellEditor: "agLargeTextCellEditor"
    },
    {
      headerName: "Confirmation Date",
      field: "confirmationDate",
      editable: true,
      cellEditor: "datePicker",
      valueGetter: row => common.dateValueGetter(row.data.confirmationDate),
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "Season",
      field: "season",
      width: 120,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: vm.seasonCodes
      },
      filterParams: { comparator: common.seasonComparator },
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "GTM Stage",
      field: "gtmStage",
      width: 145,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData[configData.lookupTypes.gtmStages])
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
      },
      suppressPaste: true
    },
    {
      headerName: "Brand",
      field: "brand",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Clusters",
      field: "requestingRegion",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Channel",
      field: "channel",
      cellClass: "noneditableCol",
      filter: true
    },
    {
      headerName: "Biggest Volume Country",
      field: "country",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Category",
      field: "category",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Finish",
      field: "finish",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Gap Type",
      field: "gapType",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Target COGS",
      field: "targetCogs",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Proto Needs",
      field: "protoNeeds",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Sample Needs",
      field: "sampleNeeds",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Class/Sub Class",
      field: "classSubClass",
      filter:true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Price Tier",
      field: "priceTier",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Type of Request",
      field: "requestType",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Pull Forward (Y/N)",
      field: "pullForward",
      cellClass: "noneditableCol",
      filter: true
    },
    {
      headerName: "Global Merchant",
      field: "globalMerchant",
      cellClass: "noneditableCol",
      filter:true
    },

    {
      headerName: "Reason Code",
      field: "requestReason",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Actual PC9",
      field: "actualPC9",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Product Description",
      field: "description",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Product Line",
      field: "productLine",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Color Look Code",
      field: "colorLookCode",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Finish Develoment Track",
      field: "finishDevelopmentTrack",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      suppressPaste: true,
      cellStyle: { overflow: "visible !important" },
      cellEditorParams: {
        values: configData.datafields.finishDevelopmentTrack
      },
    },
    {
      headerName: "FA Code",
      field: "faCode",
      editable: true,
      cellEditor: fcv,
      cellStyle: { overflow: "visible !important" },
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "Fabric Description",
      field: "fabricDescription",
      editable: true,
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "Global Merchant",
      field: "globalMerchant",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "PD Contact",
      field: "pdContact",
      editable: true,
      filter: true,
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "GD Contact",
      field: "gdContact",
      editable: true,
      filter:true,
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },

    {
      headerName: "Vendor ID",
      field: "vendorId",
      editable: true,
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "PD MRP Date",
      field: "pdmrpDate",
      editable: true,
      cellEditor: "datePicker",
      cellClassRules: {
        required: row => row.node.isSelected() && !PD_VALIDATION_MAP[row.colDef.field](row.data),
      }
    },
    {
      headerName: "PD comments",
      field: "pdComments",
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 1000,
      },
      editable: true
    }
  ];
}

function updated() {}

function save(vm) {
  common.approveRequest(vm, validation, transformToPayload);
}

function drop(vm) {}

function validation(rows) {
  for (let row of rows) {
    if (pdValidation(row.data).length > 0) return false;
  }
  return true;
}

/**
 * 
 * @param {*} params 
 * @returns {Array} array of failing fields
 */
function pdValidation(data) {
  const fieldsFailing = Object.keys(PD_VALIDATION_MAP).filter(k => !PD_VALIDATION_MAP[k](data));
  console.debug(`Fields failing validation for product request ${data.id}:`, fieldsFailing);
  return fieldsFailing;
}


/******************
 * VALIDATION  MAP
 *******************/
const gv = validations.generalTextValidation;
const dv = validations.dateValidation;
const fc = validations.fabricCode;
const PD_VALIDATION_MAP = {
  
  // base required
  season: data => gv(data.season),
  
  // more_info required
  toState: data => data.approval_state !== "MORE_INFO" || gv(data.toState),
  
  // required for new requests
  faCode: data => isCarryoverOrNonApproval(data) || data.requestType !== 'New' || fc(data.faCode),
  fabricDescription: data => isCarryoverOrNonApproval(data) || data.requestType !== 'New' || gv(data.fabricDescription),
  vendorId: data => isCarryoverOrNonApproval(data) || data.requestType !== 'New' || gv(data.vendorId),
  pdmrpDate: data => isCarryoverOrNonApproval(data) || data.requestType !== 'New' || dv(data.pdmrpDate),
  
  // required for approval of any request
  confirmationDate: data => isCarryoverOrNonApproval(data) || dv(data.confirmationDate),
  pdContact: data => isCarryoverOrNonApproval(data) || gv(data.pdContact),
  gdContact: data => isCarryoverOrNonApproval(data) || gv(data.gdContact),
}

function isCarryoverOrNonApproval(data) { return data.requestType === 'Carryover' || data.approval_state !== 'Approve'; }

function transformToPayload(rows) {
  const result = [];
  for (let row of rows) {
    const data = row.data;
    result.push({
      id: data.id,
      currentState: data.currentState,
      comments: data.pdComments,
      pdComments: data.pdComments,
      action: data.action,
      pdContact: data.pdContact,
      gdContact: data.gdContact,
      faCode: data.faCode,
      requestReason: data.requestReason,
      fabricDescription: data.fabricDescription,
      vendorId: data.vendorId,
      pdmrpDate: data.pdmrpDate,
      pdApprove: data.pdApprove,
      confirmationDate: data.confirmationDate,
      moreInfoComments: data.moreInfoComments,
    });
  }
  return result;
}

export default {
  beforeMount,
  updated,
  save,
  drop,
  pdValidation,
  getColumnDefs,
}