import common from "../utils/common";
import moment from "moment";
import AgGridThumbnail from '../components/AgGridThumbnail.vue'

async function beforeMount(vm) {
  vm.columnDefs = getColumnDefs();
  vm.defaultColDef.filter = true;
  vm.defaultColDef.floatingFilter = true;
  vm.components.StatusEditor = getStatusEditor();
  vm.sideBar = "columns";
  common.postMountColumnDefSetup(vm);
}

function getColumnDefs() {
  let checkboxColumnDef = [];
  if (common.shouldShowDropAction()) {
    checkboxColumnDef = [
      {
        headerName: "",
        field: "checkbox",
        pinned: "left",
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        filter:false,
        sortable: false,
        checkboxSelection: row => (!localStorage.getItem('role').startsWith('Regional') || row.data.requestingRegion == localStorage.getItem('region')) && !row.data.isDeleted && row.data.currentState != "6",
        width: 45
      }
    ];
  }
  const commonColumnDefs = [
    {
      headerName: "Request Id",
      field: "id",
      pinned: "left",
      width: 130,
      sortable: true,
      unSortIcon: true,
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      },
      cellClassRules: {
        dropped: function(params) {
          if (params.data.isDeleted) {
            return true;
          }
        }
      }
    },
    {
      headerName: "Submission Date (MM/DD/YYYY)",
      field: "submissionDate",
      filter:false,
      valueFormatter: function(params) {
        return moment(params.data["submissionDate"]).format("MM/DD/YYYY");
      }
    },
    {
      headerName: "Season",
      field: "season",
      filterParams: { comparator: common.seasonComparator },
    },
    {        
      headerName: "Thumbnail",
      field: 'productUrl',
      width:130,
      filter: false,
      cellRendererFramework: AgGridThumbnail,
    },
    {
      headerName: "GTM Stage",
      field: "gtmStage"
    },
    {
      headerName: "Brand",
      field: "brand"
    },
    {
      headerName: "Clusters",
      field: "requestingRegion"
    },
    {
      headerName: "Requesting Merchant",
      field: "requestingMerchant"
    },
    {
      headerName: "Channel",
      field: "channel"
    },
    {
      headerName: "Biggest Volume Country",
      field: "country"
    },
    {
      headerName: "Dropped",
      field: "isDeleted",
      width: 120,
      hide: true,
      cellClassRules: {
        dropped: function(params) {
          if (params.value) {
            return true;
          }
        }
      }
    },
    {
      headerName: "Current State",
      field: "currentState",
      width: 235,
      cellRenderer: currentState.bind(this),
    },
    {
      headerName: "Last Action",
      field: "lastAction",
    },
    {
      headerName: "Estimated Dates to Execute",
      children: [
        {
          headerName: "Forecast Submit Date (MM/DD/YYYY)",
          field: "forecastDueDate",
          valueFormatter: function(params) {
            return moment(params.data["forecastDueDate"]).format(
              "MM/DD/YYYY"
            );
          }
        },
        {
          headerName: "MRP Date (MM/DD/YYYY)",
          field: "pdmrpDate",
          valueFormatter: function(params) {
            return moment(params.data["pdmrpDate"]).format("MM/DD/YYYY");
          }
        },
        {
          headerName: "PO Issuance Date (MM/DD/YYYY)",
          valueGetter: function(params) {
            if (params.data.currentState == 6) {
              return moment(params.data["poIssuanceDate"]).format(
                "MM/DD/YYYY"
              );
            }
          }
        },
        {
          headerName: "Ex-factory (MM/DD/YYYY)",
          valueGetter: function(params) {
            if (params.data.currentState == 6) {
              return moment(params.data["exFactoryDate"]).format(
                "MM/DD/YYYY"
              );
            }
          }
        },
        {
          headerName: "IN DC (MM/DD/YYYY)",
          valueGetter: function(params) {
            if (params.data.currentState == 6) {
              return moment(params.data["inDCDate"]).format("MM/DD/YYYY");
            }
          }
        }
      ]
    },
    {
      headerName: "Request On Floor (MM/DD/YYYY)",
      field: "requestOnFloor",
      valueGetter: fieldValue => {
        if (fieldValue.data["requestOnFloor"] != undefined) {
          return moment(fieldValue.data["requestOnFloor"]).format(
            "MM/DD/YYYY"
          );
        }
      }
    },
    {
      headerName: "Requested in DC Date (MM/DD/YYYY)",
      field: "inDCDate",
      valueGetter: fieldValue => {
        if (fieldValue.data["inDCDate"] != undefined) {
          return moment(fieldValue.data["inDCDate"]).format("MM/DD/YYYY");
        }
      }
    },
    {
      headerName: "Request Type",
      field: "requestType"
    },
    {
      headerName: "Gap Type",
      field: "gapType"
    },
    {
      headerName: "Explanation of Request",
      field: "requestExplanation"
    },
    {
      headerName: "PC9",
      field: "actualPC9"
    },
    {
      headerName: "Product Description",
      field: "description"
    },
    {
      headerName: "Gender",
      field: "gender"
    },
    {
      headerName: "Category",
      field: "category"
    },
    {
      headerName: "Class & Subclass",
      field: "classSubClass"
    },
    {
      headerName: "Price Tier",
      field: "priceTier"
    },
    {
      headerName: "Reason Code",
      field: "requestReason"
    },
    {
      headerName: "Pull Forward",
      field: "pullForward"
    },
    {
      headerName: "Fabric",
      field: "fabric"
    },
    {
      headerName: "Finish",
      field: "finish"
    },
    {
      headerName: "Fabric Code",
      field: "faCode"
    },
    {
      headerName: "Fabric Description",
      field: "fabricDescription"
    },
    {
      headerName: "Vendor Id",
      field: "vendorId"
    },
    {
      headerName: "Target COGS",
      field: "targetCogs"
    },
    {
      headerName: "Proto Needs",
      field: "protoNeeds"
    },
    {
      headerName: "Sample Needs",
      field: "sampleNeeds"
    },
    {
      headerName: "Submitted By",
      field: "submittedBy"
    },
    {
      headerName: "Product Line",
      field: "productLine",
    },
    {
      headerName: "Color Look Code",
      field: "colorLookCode",
    },
    {
      headerName: "Finish Development Track",
      field: "finishDevelopmentTrack",
    },
    {
      headerName: "Account Name",
      field: "accountName",
    },
    {
      headerName: "Exclusivity",
      field: "exclusivity",
    },
    {
      headerName: "RM Comments",
      field: "rmComments"
    },
    {
      headerName: "RL Comments",
      field: "rlComments"
    },
    {
      headerName: "RP Comments",
      field: "rpComments"
    },
    {
      headerName: "Global Merchant",
      field: "globalMerchant"
    },
    {
      headerName: "GM Comments",
      field: "gmiComments"
    },
    {
      headerName: "GML Comments",
      field: "gmlComments"
    },
    {
      headerName: "PD Contact",
      field: "pdContact"
    },
    {
      headerName: "GD Contact",
      field: "gdContact"
    },
    {
      headerName: "PD Comments",
      field: "pdComments"
    },
    {
      headerName: "SP Contact",
      field: "spContact"
    },
    {
      headerName: "SP Comments",
      field: "spComments"
    },
    {
      headerName: "Anticipated Volume",
      field: "anticipatedVolume",
      cellStyle: function(params) {
        if(params.value >= 1500 && params.value <= 5000){
          return {  backgroundColor: "yellow"}
        } else {
          return { backgroundColor: ""}
        }
      },
    },
    {
      headerName: "Last Season Offered",
      field: "lastSeasonOffered"
    },
    {
      headerName: "Image Reference PC9",
      field: "imageReferencePC9",
    },
    {
      headerName: "Account Name",
      field: "accountName",
    },
    {
      headerName: "Exclusivity",
      field: "exclusivity",
    },
  ];
  return checkboxColumnDef.concat(commonColumnDefs);
}


function currentState(row) {
  switch(row.value){
    case '0':
      return 'Regional Lead';
    case '1':
      return "Regional Planning Lead"
    case '2':
      return "Global Merch"
    case '3':
      return "Global Merch (Global Lead approved)"
    case '4':
      return "Product Development"
    case '5':
      return "Supply Planning"
    case '6':
      return "Supply Planning Approved"
    default:
      return row.value
  }
}
function updated() { }

function getStatusEditor() {
  function StatusEditor() {}
  StatusEditor.prototype.getGui = function() {
    return this.eGui;
  };
  StatusEditor.prototype.getValue = function() {
    return this.value;
  };
  StatusEditor.prototype.isPopup = function() {
    return true;
  };
  StatusEditor.prototype.init = function(params) {
    this.value = params.value;
    var tempElement = document.createElement("span");
    var statusValue = 0;
    switch (params.colDef.headerName) {
      case "Regional Merchant":
        statusValue = 0;
        break;
      case "Regional Lead":
        statusValue = 1;
        break;
      case "Regional Planning Lead":
        statusValue = 2;
        break;
      case "Global Merch Lead":
        statusValue = 4;
        break;
      case "Product Development":
        statusValue = 5;
        break;
      case "Supply Planning":
        statusValue = 6;
        break;
    }
    if (parseInt(this.value) >= statusValue) {
      tempElement.setAttribute("style", "color:green");
    } else {
      tempElement.setAttribute("style", "color:orange");
    }

    this.eGui = tempElement.firstChild;
  };
  return StatusEditor;
}

export default {
  beforeMount,
  updated,
  getColumnDefs,
}